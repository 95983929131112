import React from 'react';
import { Box, Container, styled, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { HowToApplyForm } from '../../components/form/';
import { colors } from '../../styles/globals';

const StyledBox = styled(Box)({
  background: `url(https://res.cloudinary.com/dywofwzdx/image/upload/v1671988954/Screenshot_20220107-084411_Gallery_py5von.jpg)`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
});

const ClaimRequirement = () => {
  return (
    <>
      <Helmet>
        <title>Claim Requirement | Pch Officials</title>
      </Helmet>
      <StyledBox height={['40vh', '55vh']} />;
      <Container maxWidth={'md'}>
        <Box paddingY={6}>
          <Typography
            variant={'h2'}
            fontSize={['1.4rem', '2rem']}
            color={colors.TITLE}
            textTransform={'capitalise'}
            fontWeight={600}
            //   letterSpacing={'2px'}
            textAlign={'center'}
          >
            You have to fill the following delivery information in order to
            continue your winning and make your delivery
          </Typography>
        </Box>
        <Container maxWidth={'sm'}>
          <Box paddingX={1} paddingTop={['', 2]} paddingBottom={[5, 8]}>
            <HowToApplyForm />{' '}
            <Typography variant='body2' color={colors.NAV_TEXT} my={8}>
              <Box component='span' color='red'>
                NOTE:{' '}
              </Box>
              We advise you to abide with the Terms and Conditions so that we
              can proceed.
            </Typography>
          </Box>
        </Container>
      </Container>
    </>
  );
};

export default ClaimRequirement;
